import { MathJaxContext } from "better-react-mathjax";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrolltotop/ScrollToTop";
import Nopage from "./pages/nopage/Nopage";
import SubTopicQuestion from "./pages/subtopicQuestion/SubTopicQuestion";
import PrivateRoutes from "./utils/PrivateRoutes";
import appStore from "./utils/appStore";
import DisableJs from "./utils/disableJs";

const config = {
  loader: { load: ["input/tex", "output/chtml"] },
  tex: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};

const App = () => {
  return (
    <Provider store={appStore}>
      <DisableJs />
      <MathJaxContext version={3} config={config}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/:subject/:topic/:subTopic/:id"
                element={<SubTopicQuestion />}
              />
              <Route
                path="/:subject/:topic/:subTopic/:id/:accessToken"
                element={<SubTopicQuestion />}
              />
            </Route>
            <Route path="*" element={<Nopage />} />
          </Routes>
        </BrowserRouter>
      </MathJaxContext>
    </Provider>
  );
};

export default App;
