import React, { useState, useEffect, useCallback } from "react";
import "./question.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import QuestionV2 from "./QuestionV2";
import RecommendedSubTopics from "../recommendedSubTopics/RecommendedSubTopics";
import NoData from "../Loader/NoData";
import Logo from "../../assets/images/logo.png";
import InstructionModal from "../instruction/InstructionContentModal";
import QuestionPaperModal from "./QuestionPaperModal";
import AnswerScreen from "../AnswerScreen/AnswerScreen";
import { API, PARENTAPI } from "../../utils/constants";
import CuetLoader from "../Loader/Loader";

const PracticeQuestions = ({ data }) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showQuestionPaperModal, setShowQuestionPaperModal] = useState(false);
  const [scoreData, setScoreData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmittedScore, setHasSubmittedScore] = useState(false);

  const { id, subject, subTopic, accessToken } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  // Get testSubmitted state from Redux
  const testSubmitted = useSelector(
    (state) => state.user.mock_test.testSubmitted
  );

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
    setShowQuestionPaperModal(false);
  };

  const toggleQuestionPaperModal = () => {
    setShowQuestionPaperModal(!showQuestionPaperModal);
    setShowInstructions(false);
  };

  // Calculate score with proper error handling
  // Wrapped with useCallback to avoid dependency issues
  const calculateScore = useCallback(() => {
    const dataLength = data && Array.isArray(data) ? data.length : 0;
    let attemptedQuestions = 0;
    let score = 0;
    let correctQuestions = 0;
    let incorrectQuestions = 0;

    let optionsUI = [];
    let questionStatus = [];
    let answers = [];

    try {
      optionsUI =
        JSON.parse(localStorage.getItem("optionsUI")) ||
        Array(dataLength).fill(undefined);
      questionStatus =
        JSON.parse(localStorage.getItem("questionStatus")) ||
        Array(dataLength).fill("not_visited");
    } catch (error) {
      console.error("Error parsing data from localStorage:", error);
      optionsUI = Array(dataLength).fill(undefined);
      questionStatus = Array(dataLength).fill("not_visited");
    }

    // Extract answers from data
    if (data && Array.isArray(data)) {
      data.forEach((questionObject) => {
        if (
          questionObject &&
          questionObject.subQuestions &&
          Array.isArray(questionObject.subQuestions)
        ) {
          questionObject.subQuestions.forEach((subQuestion) => {
            if (subQuestion) {
              answers.push(subQuestion.correctOptionIndex);
            }
          });
        }
      });
    }

    if (data && Array.isArray(data) && questionStatus && optionsUI && answers) {
      for (let i = 0; i < data.length; i++) {
        if (
          questionStatus[i] === "answered" ||
          questionStatus[i] === "review_answered"
        ) {
          attemptedQuestions++;

          if (optionsUI[i] === answers[i]) {
            score += 5;
            correctQuestions++;
          } else {
            score -= 1;
            incorrectQuestions++;
          }
        }
      }
    }

    return {
      score,
      attemptedQuestions,
      correctQuestions,
      incorrectQuestions,
      unattemptedQuestions: dataLength - attemptedQuestions,
    };
  }, [data]);

  // Effect to handle API call when test is submitted
  useEffect(() => {
    if (
      !testSubmitted ||
      hasSubmittedScore ||
      !data ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return;
    }

    setIsLoading(true);

    const { correctQuestions, incorrectQuestions, unattemptedQuestions } =
      calculateScore();

    // Get time taken
    const timeTaken = parseInt(localStorage.getItem("timeTaken") || "0");

    // Parse subtopic safely
    const newsubtopic = parseInt(subTopic || "0");

    const payload = {
      topicName: subject,
      testType: "mock",
      testNumber: newsubtopic,
      correctAnswers: correctQuestions,
      incorrectAnswers: incorrectQuestions,
      unattemptedQuestions: unattemptedQuestions,
      timeTaken: timeTaken,
    };

    // Submit score
    axios
      .post(`${API}/scores/submit-score/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setScoreData(response.data);
        console.log("Score submitted successfully:", response.data);
      })
      .catch((error) => {
        console.error(
          "Error submitting score:",
          error.response ? error.response.data : error.message
        );
      })
      .finally(() => {
        setIsLoading(false);
        setHasSubmittedScore(true);
      });
  }, [
    testSubmitted,
    data,
    id,
    subject,
    subTopic,
    accessToken,
    hasSubmittedScore,
    calculateScore,
  ]);

  return (
    <>
      {showInstructions && (
        <InstructionModal
          toggleInstruction={toggleInstructions}
          isOpen={showInstructions}
        />
      )}
      <QuestionPaperModal
        isOpen={showQuestionPaperModal}
        toggleQuestionPaper={toggleQuestionPaperModal}
        data={data}
      />
      {
        <section className="question-practice">
          {isLoading ? (
            <CuetLoader />
          ) : data ? (
            <section className="testknock-mock-test w-100 ">
              <div className="d-flex justify-content-center level-0 align-items-center">
                <div className="testknock-left">
                  <div className="text-center test-title">
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <img
                        src={Logo}
                        alt="cuet-testknock-logo"
                        style={{ height: "25px" }}
                      />
                      TESTKNOCK TEST PLATFORM
                    </div>
                  </div>
                </div>
                <div className="ps-2 testknock-right">
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    {!testSubmitted ? (
                      <div className="text-nowrap gap-2 d-flex justify-content-center align-items-center ">
                        <button
                          className="modal-button"
                          onClick={toggleQuestionPaperModal}
                        >
                          Question Paper
                        </button>
                        <button
                          className="modal-button"
                          onClick={toggleInstructions}
                        >
                          Instructions
                        </button>
                      </div>
                    ) : (
                      <div className="text-nowrap mt-2 gap-2 d-flex justify-content-center align-items-center ">
                        <button
                          className="score-card-btn"
                          onClick={() => window.location.reload()}
                        >
                          Retake Test
                        </button>
                        <Link
                          className="score-card-btn"
                          style={{ textDecoration: "none" }}
                          to={`${PARENTAPI}`}
                        >
                          Home
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex level-1">
                <div className="testknock-left pe-0">
                  <RecommendedSubTopics />
                </div>
                {!testSubmitted && (
                  <div className="ps-0 testknock-right">
                    <div className="ct-right">
                      <div className="ct-profile-image">
                        <img
                          src="https://kananprep-assets.s3.ap-south-1.amazonaws.com/testengine/testengine-items/catlayout/NewCandidateImage.jpg"
                          alt="profile"
                        />
                      </div>
                      <div className="ct-profile-details">
                        <div className="ct-username">{user?.name}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-100 level-2">
                {testSubmitted && hasSubmittedScore ? (
                  <AnswerScreen data={data} scoreData={scoreData} />
                ) : (
                  <QuestionV2 data={data} />
                )}
              </div>
            </section>
          ) : (
            <NoData />
          )}
        </section>
      }
    </>
  );
};

export default PracticeQuestions;
