import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Cell,
  PieChart,
  Pie,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from "recharts";

const SectionalComparisonChart = ({
  userScore,
  userTime,
  userCorrect,
  userIncorrect,
  userUnattempted,
  matchedTopperScore,
  matchedTopperTime,
  matchedTopperCorrect,
  matchedTopperIncorrect,
  matchedTopperUnattempted,
}) => {
  console.log(matchedTopperTime, userTime);

  // Format time for display (minutes:seconds)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${remainingSeconds}`;
  };

  // Format data for the main performance chart
  const performanceData = [
    {
      name: "Score",
      Your_Performance: userScore,
      Top_Performance: matchedTopperScore,
    },
    {
      name: "Time (min)",
      Your_Performance: userTime,
      Your_Performance_Display: formatTime(userTime),
      Top_Performance: matchedTopperTime,
      Top_Performance_Display: formatTime(matchedTopperTime),
    },
    {
      name: "Accuracy",
      Your_Performance: userScore > 0 ? Math.round((userScore / 250) * 100) : 0,
      Top_Performance:
        matchedTopperScore > 0
          ? Math.round((matchedTopperScore / 250) * 100)
          : 0,
    },
  ];

  // Format data for the answer distribution chart
  const userAnswerData = [
    { name: "Correct", value: userCorrect, color: "#4CAF50" },
    { name: "Incorrect", value: userIncorrect, color: "#F44336" },
    { name: "Unattempted", value: userUnattempted, color: "#9E9E9E" },
  ];

  const topperAnswerData = [
    { name: "Correct", value: matchedTopperCorrect, color: "#4CAF50" },
    { name: "Incorrect", value: matchedTopperIncorrect, color: "#F44336" },
    { name: "Unattempted", value: matchedTopperUnattempted, color: "#9E9E9E" },
  ];

  // Format data for the radar chart
  const radarData = [
    { subject: "Score", A: userScore, B: matchedTopperScore, fullMark: 250 },
    {
      subject: "Time Efficiency",
      A: 100 - userTime / 36,
      B: 100 - matchedTopperTime / 36,
      fullMark: 100,
    },
    {
      subject: "Accuracy",
      A: userScore > 0 ? Math.round((userScore / 250) * 100) : 0,
      B:
        matchedTopperScore > 0
          ? Math.round((matchedTopperScore / 250) * 100)
          : 0,
      fullMark: 100,
    },
    {
      subject: "Correct Answers",
      A: userCorrect,
      B: matchedTopperCorrect,
      fullMark: Math.max(userCorrect, matchedTopperCorrect) * 1.2,
    },
    {
      subject: "Attempted",
      A: userCorrect + userIncorrect,
      B: matchedTopperCorrect + matchedTopperIncorrect,
      fullMark:
        Math.max(
          userCorrect + userIncorrect,
          matchedTopperCorrect + matchedTopperIncorrect
        ) * 1.2,
    },
  ];

  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="bg-white p-3 rounded shadow border"
          style={{
            backgroundColor: "white",
            padding: "0.75rem",
            borderRadius: "0.25rem",
            boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,.075)",
            border: "1px solid #dee2e6",
          }}
        >
          <p className="fw-semibold" style={{ fontWeight: "600" }}>
            {label}
          </p>
          {payload.map((entry, index) => {
            // Special handling for time display
            if (
              label === "Time (min)" &&
              (entry.name === "Current Performance" ||
                entry.name === "Best Performance")
            ) {
              const displayKey =
                entry.name === "Current Performance"
                  ? "Your_Performance_Display"
                  : "Top_Performance_Display";
              const displayValue = payload[0].payload[displayKey];
              return (
                <p key={index} style={{ color: entry.color }}>
                  {entry.name}: {displayValue}
                </p>
              );
            }
            return (
              <p key={index} style={{ color: entry.color }}>
                {entry.name}: {entry.value}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mt-4" style={{ marginTop: "1rem" }}>
      <div className="mb-4" style={{ marginBottom: "2rem" }}>
        <div
          className="bg-white p-4 rounded shadow"
          style={{
            backgroundColor: "white",
            padding: "1rem",
            borderRadius: "0.5rem",
            boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,.075)",
          }}
        >
          <h3
            className="fs-5 fw-bold mb-3 text-center"
            style={{
              fontSize: "1.25rem",
              fontWeight: "700",
              marginBottom: "0.75rem",
              textAlign: "center",
            }}
          >
            Performance Comparison
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={performanceData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                dataKey="Your_Performance"
                fill="#ff86b0"
                name="Current Performance"
              />
              <Bar
                dataKey="Top_Performance"
                fill="#7cb5ec"
                name="Best Performance"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="row g-3">
        <div className="col-md-6">
          <div
            className="bg-white p-4 rounded shadow"
            style={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "0.5rem",
              boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,.075)",
            }}
          >
            <h3
              className="fs-5 fw-bold mb-3 text-center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                marginBottom: "0.75rem",
                textAlign: "center",
              }}
            >
              Answer Distribution
            </h3>
            <div className="d-flex flex-column pb-2 flex-md-row justify-content-around align-items-center">
              <div className="text-center">
                <h4
                  className="fw-semibold mb-2"
                  style={{ fontWeight: "600", marginBottom: "0.5rem" }}
                >
                  Current
                </h4>
                <ResponsiveContainer width={150} height={150}>
                  <PieChart>
                    <Pie
                      data={userAnswerData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={5}
                      dataKey="value"
                      nameKey="name"
                    >
                      {userAnswerData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="text-center">
                <h4
                  className="fw-semibold mb-2"
                  style={{ fontWeight: "600", marginBottom: "0.5rem" }}
                >
                  Best
                </h4>
                <ResponsiveContainer width={150} height={150}>
                  <PieChart>
                    <Pie
                      data={topperAnswerData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={5}
                      dataKey="value"
                      nameKey="name"
                    >
                      {topperAnswerData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div
              className="d-flex justify-content-center pb-3 mt-3"
              style={{ marginTop: "0.75rem" }}
            >
              <div
                className="d-flex flex-wrap justify-content-center"
                style={{ gap: "1rem" }}
              >
                {userAnswerData.map((entry, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <div
                      className="me-1"
                      style={{
                        width: "0.75rem",
                        height: "0.75rem",
                        marginRight: "0.25rem",
                        backgroundColor: entry.color,
                      }}
                    ></div>
                    <span style={{ fontSize: "0.875rem" }}>{entry.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div
            className="bg-white p-4 rounded shadow"
            style={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "0.5rem",
              boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,.075)",
            }}
          >
            <h3
              className="fs-5 fw-bold mb-3 text-center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                marginBottom: "0.75rem",
                textAlign: "center",
              }}
            >
              Performance Radar
            </h3>
            <ResponsiveContainer width="100%" height={250}>
              <RadarChart outerRadius={90} data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar
                  name="Current Performance"
                  dataKey="A"
                  stroke="#ff86b0"
                  fill="#ff86b0"
                  fillOpacity={0.6}
                />
                <Radar
                  name="Best Performance"
                  dataKey="B"
                  stroke="#7cb5ec"
                  fill="#7cb5ec"
                  fillOpacity={0.6}
                />
                <Legend />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionalComparisonChart;
