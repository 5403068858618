import React from "react";
import {
  FaMedal,
  FaChartLine,
  FaStopwatch,
  FaBullseye,
  FaCheck,
} from "react-icons/fa";
import "./Score.css";

const ToppersComparisonComponent = ({ attemptDetails, ranking }) => {
  const { topPerformers, userRank } = ranking;

  // Get user from localStorage
  const localStorageUser = JSON.parse(localStorage.getItem("user")) || {};

  // Format time function (minutes:seconds)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${remainingSeconds}`;
  };

  // Calculate accuracy percentage
  const calculateAccuracy = (correct, attempted) => {
    return attempted > 0 ? Math.round((correct / attempted) * 100) : 0;
  };

  // Generate initial avatar for users without profile picture
  const getInitialAvatar = (name) => {
    return name && name.length > 0 ? name.charAt(0).toUpperCase() : "?";
  };

  // Prepare data for display
  const data = [
    {
      label: "Rank",
      icon: <FaMedal style={{ color: "#e67e22" }} />,
      topper: topPerformers.slice(0, 3).map((performer) => performer.rank),
      // Always show rank from userRank
      user: userRank.rank,
      iconComponent: (
        <div className="icon-container">
          <FaMedal size={24} style={{ color: "#e67e22" }} />
        </div>
      ),
    },
    {
      label: "Score",
      icon: <FaChartLine style={{ color: "#2ecc71" }} />,
      topper: topPerformers.slice(0, 3).map((performer) => performer.score),
      // Use attemptDetails for user score
      user: attemptDetails.score,
      iconComponent: (
        <div className="icon-container score-icon">
          <div className="gauge">
            <div className="gauge-fill"></div>
            <div className="gauge-needle"></div>
          </div>
        </div>
      ),
    },
    {
      label: "Time",
      icon: <FaStopwatch style={{ color: "#3498db" }} />,
      topper: topPerformers
        .slice(0, 3)
        .map((performer) => formatTime(performer.timeTaken)),
      // Use attemptDetails for user time
      user: formatTime(attemptDetails.timeTaken),
      iconComponent: (
        <div className="icon-container">
          <FaStopwatch size={24} style={{ color: "#3498db" }} />
        </div>
      ),
    },
    {
      label: "Accuracy",
      icon: <FaBullseye style={{ color: "#e67e22" }} />,
      topper: topPerformers.slice(0, 3).map((performer) => {
        return calculateAccuracy(
          performer.correctAnswers,
          performer.attemptedQuestions
        );
      }),
      // Use attemptDetails for user accuracy
      user: calculateAccuracy(
        attemptDetails.correctAnswers,
        attemptDetails.correctAnswers + attemptDetails.incorrectAnswers
      ),
      iconComponent: (
        <div className="icon-container">
          <FaBullseye size={24} style={{ color: "#e67e22" }} />
        </div>
      ),
    },
    {
      label: "Attempt Rate",
      icon: <FaCheck style={{ color: "#3498db" }} />,
      topper: topPerformers
        .slice(0, 3)
        .map((performer) => performer.attemptedQuestions),
      // Use attemptDetails for user attempt rate
      user: attemptDetails.correctAnswers + attemptDetails.incorrectAnswers,
      iconComponent: (
        <div className="icon-container attempt-rate-icon">
          <div className="attempt-rate-img"></div>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {/* Left Column - Labels & Icons */}
        <div className="col-md-3">
          {data.map((item, index) => (
            <div
              key={index}
              className="metric-row d-flex align-items-center mb-4"
            >
              <div className="metric-icon-container">{item.icon}</div>
              <span className="metric-label ms-3">{item.label}</span>
            </div>
          ))}
        </div>

        {/* Right Column - Values & Dashed Lines */}
        <div className="col-md-9">
          {data.map((item, index) => (
            <div
              key={index}
              className="comparison-row d-flex align-items-center position-relative mb-4"
            >
              {/* Dashed Line */}
              <div className="dashed-line"></div>

              {/* Topper Values */}
              <div className="topper-values d-flex position-relative">
                {item.topper.map((val, idx) => (
                  <div key={idx} className="topper-circle-container">
                    <div className="topper-rank-badge">{idx + 1}</div>
                    <div className="topper-circle">
                      {val}
                      {item.label === "Accuracy" && "%"}
                    </div>
                    <div className="topper-avatar">
                      {topPerformers[idx]?.profilePic ? (
                        <img
                          src={topPerformers[idx].profilePic}
                          alt={topPerformers[idx].userName}
                          className="avatar-img"
                        />
                      ) : (
                        <div className="avatar-initial">
                          {getInitialAvatar(topPerformers[idx]?.userName)}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* User Value */}
              <div className="user-value-container ms-auto d-flex flex-column align-items-center position-relative">
                <span className="user-value-label">Your {item.label}</span>
                <div className="user-value-circle">
                  {item.user}
                  {item.label === "Accuracy" && "%"}
                </div>
                <div className="user-avatar">
                  {localStorageUser.profilePic ? (
                    <img
                      src={localStorageUser.profilePic}
                      alt="Your avatar"
                      className="avatar-img"
                    />
                  ) : (
                    <div className="avatar-initial">
                      {localStorageUser.name
                        ? getInitialAvatar(localStorageUser.name)
                        : "N"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToppersComparisonComponent;
