import React, { useState } from "react";
import Score from "../../assets/images/practicetest/score.gif";
import Rank from "../../assets/images/practicetest/rank.gif";
import time from "../../assets/images/practicetest/time.gif";
import percentage from "../../assets/images/practicetest/percentage.gif";
import { IoMdClose } from "react-icons/io";
import SectionalComparisonChart from "./SectionalComparisonChart";
import ToppersComparisonComponent from "./ToppersComparisonComponent";

const MockReport = ({ scoreData, onClose, isOpen }) => {
  // Extract values from data
  const { attemptDetails, ranking } = scoreData;
  const { userRank, topPerformers, testInfo } = ranking;
  const [activeTab, setActiveTab] = useState("sectional");

  // Score and time data - using the provided attemptDetails
  const score = attemptDetails.score;
  const correctAnswers = attemptDetails.correctAnswers;
  const incorrectAnswers = attemptDetails.incorrectAnswers;
  const unattempted = attemptDetails.unattemptedQuestions;
  const totalQuestions = attemptDetails.totalQuestions;
  const totalTimeSpent = attemptDetails.timeTaken; // in seconds
  const totalTimeAllowed = 60 * 60; // 60 minutes in seconds
  const totalMaxScore = 250; // Total maximum score
  const scorePercentage = (score / totalMaxScore) * 100;
  const percentile = (correctAnswers / totalQuestions) * 100;

  // Time distribution calculations
  const averageTimePerQuestion =
    totalTimeSpent / (correctAnswers + incorrectAnswers);
  const correctAnswersTime = Math.floor(
    correctAnswers * averageTimePerQuestion
  );
  const incorrectAnswersTime = Math.floor(
    incorrectAnswers * averageTimePerQuestion
  );
  const unusedTime = totalTimeAllowed - totalTimeSpent;

  // Section data
  const sectionScore = `${score}/${totalMaxScore}`;
  const sectionTime = `${Math.floor(totalTimeSpent / 60)}:${(
    totalTimeSpent % 60
  )
    .toString()
    .padStart(2, "0")}`;
  const sectionPercentile = percentile;

  // Calculate percentages for time spent
  const unusedTimePercentage = (unusedTime / totalTimeAllowed) * 100;
  const correctTimePercentage = (correctAnswersTime / totalTimeAllowed) * 100;
  const incorrectTimePercentage =
    (incorrectAnswersTime / totalTimeAllowed) * 100;

  // Calculate percentages for answer distribution
  const totalQuestionsCount = correctAnswers + incorrectAnswers + unattempted;
  const correctPercentage = (correctAnswers / totalQuestionsCount) * 100;
  const incorrectPercentage = (incorrectAnswers / totalQuestionsCount) * 100;
  const unattemptedQuestionsPercentage =
    (unattempted / totalQuestionsCount) * 100;

  // Get topper's data
  const topper = topPerformers[0];
  const topperScore = `${topper.score}/${totalMaxScore}`;
  const topperTime = `${Math.floor(topper.timeTaken / 60)}:${(
    topper.timeTaken % 60
  )
    .toString()
    .padStart(2, "0")}`;

  if (!isOpen) return null;

  return (
    <div
      className="modal d-block position-fixed top-0 start-0 w-100 h-100"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
    >
      <div
        className="modal-dialog modal-xl mx-auto my-3"
        style={{
          Height: "95vh",
          overflowY: "auto",
          scrollBehavior: "smooth",
          borderRadius: "10px",
          zIndex: 1051,
        }}
      >
        <div className="modal-content bg-light">
          <div
            className="position-sticky top-0 end-0 d-flex justify-content-end p-2 bg-light"
            style={{ zIndex: 10 }}
          >
            <button
              onClick={onClose}
              className="btn btn-warning rounded-circle text-white d-flex align-items-center justify-content-center"
              style={{ width: "30px", height: "30px", padding: 0 }}
              aria-label="Close"
            >
              <IoMdClose className="fs-5" />
            </button>
          </div>

          <div className="p-3 p-md-4">
            {/* Report Button */}
            <div className="d-flex justify-content-center mb-4">
              <button className="btn btn-warning text-white px-4">
                Report
              </button>
            </div>

            {/* Score Metrics Row */}
            <div className="row g-4 mb-4">
              <div className="col-12 col-sm-6 col-md-3">
                <div className="border rounded p-3 shadow">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={Score}
                      alt="Icon"
                      className="object-fit-contain"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span className="small fw-medium text-secondary">
                      Score
                    </span>
                    <span className="ms-1 small text-warning">
                      {sectionScore}
                    </span>
                  </div>
                  <div className="progress mt-2" style={{ height: "8px" }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: `${scorePercentage}%` }}
                      aria-valuenow={scorePercentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="border rounded p-3 shadow">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={Rank}
                      alt="Icon"
                      className="object-fit-contain"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span className="small fw-medium text-secondary">Rank</span>
                    <span className="ms-1 small text-warning">
                      {userRank.rank}/{userRank.totalParticipants}
                    </span>
                  </div>
                  <div className="progress mt-2" style={{ height: "8px" }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{
                        width: `${
                          ((userRank.totalParticipants - userRank.rank + 1) /
                            userRank.totalParticipants) *
                          100
                        }%`,
                      }}
                      aria-valuenow={
                        ((userRank.totalParticipants - userRank.rank + 1) /
                          userRank.totalParticipants) *
                        100
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="border rounded p-3 shadow">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={time}
                      alt="Icon"
                      className="object-fit-contain"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span className="small fw-medium text-secondary">Time</span>
                    <span className="ms-1 small text-warning">
                      {Math.floor(totalTimeSpent / 60)}:
                      {(totalTimeSpent % 60).toString().padStart(2, "0")}
                    </span>
                  </div>
                  <div className="progress mt-2" style={{ height: "8px" }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{
                        width: `${(totalTimeSpent / totalTimeAllowed) * 100}%`,
                      }}
                      aria-valuenow={(totalTimeSpent / totalTimeAllowed) * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="border rounded p-3 shadow">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={percentage}
                      alt="Icon"
                      className="object-fit-contain"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span className="small fw-medium text-secondary">
                      Percentage
                    </span>
                    <span className="ms-1 small text-warning">
                      {percentile.toFixed(2)}%
                    </span>
                  </div>
                  <div className="progress mt-2" style={{ height: "8px" }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: `${percentile}%` }}
                      aria-valuenow={percentile}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Topper's Comparison */}
            <div className="mb-4">
              <h5 className="small fw-medium mb-3">Topper's Comparison</h5>
              <div className="row g-4">
                <div className="col-12 col-sm-6">
                  <div className="border rounded p-3 shadow">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={Score}
                        alt="Icon"
                        className="object-fit-contain"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <span className="small fw-medium text-secondary">
                        Topper's Score
                      </span>
                      <span className="ms-1 small text-warning">
                        {topperScore}
                      </span>
                    </div>
                    <div className="progress mt-2" style={{ height: "8px" }}>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{
                          width: `${(topper.score / totalMaxScore) * 100}%`,
                        }}
                        aria-valuenow={(topper.score / totalMaxScore) * 100}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6">
                  <div className="border rounded p-3 shadow">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={time}
                        alt="Icon"
                        className="object-fit-contain"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <span className="small fw-medium text-secondary">
                        Topper's Time
                      </span>
                      <span className="ms-1 small text-warning">
                        {topperTime}
                      </span>
                    </div>
                    <div className="progress mt-2" style={{ height: "8px" }}>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{
                          width: `${
                            (topper.timeTaken / totalTimeAllowed) * 100
                          }%`,
                        }}
                        aria-valuenow={
                          (topper.timeTaken / totalTimeAllowed) * 100
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Overall Analysis */}
            <div className="bg-info bg-opacity-10 p-4 rounded">
              <h5 className="small fw-medium mb-4 text-center">
                Overall Analysis
              </h5>

              <div className="row g-4 mb-4">
                {/* Answer Distribution Donut Chart */}
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                  <div
                    className="position-relative mb-4"
                    style={{ width: "128px", height: "128px" }}
                  >
                    <svg viewBox="0 0 100 100" className="w-100 h-100">
                      {/* Correct answers */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#0d6efd"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * correctPercentage) / 100
                        }
                        transform="rotate(-90 50 50)"
                      />
                      {/* Incorrect answers */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#fd7e14"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * incorrectPercentage) / 100
                        }
                        transform={`rotate(${
                          (correctPercentage / 100) * 360 - 90
                        } 50 50)`}
                      />
                      {/* Unattempted questions */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#dc3545"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * unattemptedQuestionsPercentage) / 100
                        }
                        transform={`rotate(${
                          ((correctPercentage + incorrectPercentage) / 100) *
                            360 -
                          90
                        } 50 50)`}
                      />
                      <circle cx="50" cy="50" r="30" fill="white" />
                    </svg>
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center small text-center">
                      <span className="fw-medium">Answer</span>
                      <span className="fw-medium">Distribution</span>
                    </div>
                  </div>
                  <div className="mt-2 small">
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div
                        className="rounded-circle bg-primary"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Correct</span>
                      <span className="ms-auto fw-medium">
                        {correctPercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div
                        className="rounded-circle bg-warning"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Incorrect</span>
                      <span className="ms-auto fw-medium">
                        {incorrectPercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <div
                        className="rounded-circle bg-danger"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Unattempted</span>
                      <span className="ms-auto fw-medium">
                        {unattemptedQuestionsPercentage.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>

                {/* Time Spent Donut Chart */}
                <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                  <div
                    className="position-relative mb-4"
                    style={{ width: "128px", height: "128px" }}
                  >
                    <svg viewBox="0 0 100 100" className="w-100 h-100">
                      {/* Correct Time Segment (Green) */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#198754"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * correctTimePercentage) / 100
                        }
                        transform="rotate(-90 50 50)"
                      />

                      {/* Incorrect Time Segment (Yellow) */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#ffc107"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * incorrectTimePercentage) / 100
                        }
                        transform={`rotate(${
                          (correctTimePercentage / 100) * 360 - 90
                        } 50 50)`}
                      />

                      {/* Unused Time Segment (Gray) */}
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#6c757d"
                        strokeWidth="20"
                        strokeDasharray="251.2"
                        strokeDashoffset={
                          251.2 - (251.2 * unusedTimePercentage) / 100
                        }
                        transform={`rotate(${
                          ((correctTimePercentage + incorrectTimePercentage) /
                            100) *
                            360 -
                          90
                        } 50 50)`}
                      />
                      <circle cx="50" cy="50" r="30" fill="white" />
                    </svg>
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center small text-center">
                      <span className="fw-medium">Time Spent</span>
                    </div>
                  </div>

                  <div className="mt-2 small">
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div
                        className="rounded-circle bg-success"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Correct Answers</span>
                      <span className="ms-auto fw-medium">
                        {correctTimePercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div
                        className="rounded-circle bg-warning"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Incorrect Answers</span>
                      <span className="ms-auto fw-medium">
                        {incorrectTimePercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <div
                        className="rounded-circle bg-secondary"
                        style={{ width: "8px", height: "8px" }}
                      ></div>
                      <span>Unused Time</span>
                      <span className="ms-auto fw-medium">
                        {unusedTimePercentage.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>

                {/* Performance Table */}
                <div className="col-12 col-md-4">
                  <div className="rounded overflow-hidden shadow-sm">
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr className="bg-warning bg-opacity-25">
                          <th className="py-2 px-2 text-start fw-medium text-secondary small">
                            Sections
                          </th>
                          <th className="py-2 px-2 text-start fw-medium text-secondary small">
                            Score
                          </th>
                          <th className="py-2 px-2 text-start fw-medium text-secondary small">
                            Time Spent
                          </th>
                          <th className="py-2 px-2 text-start fw-medium text-secondary small">
                            Percentile
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-2 px-2 text-secondary small">{`${
                            testInfo.topicName
                              .replace(/_/g, " ") // Replace underscores with spaces
                              .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
                          } ${
                            testInfo.testType
                              .replace(/_/g, " ") // Replace underscores with spaces
                              .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
                          } Test ${testInfo.testNumber}`}</td>

                          <td className="py-2 px-2 text-secondary small">
                            {sectionScore}
                          </td>
                          <td className="py-2 px-2 text-secondary small">
                            {sectionTime}
                          </td>
                          <td className="py-2 px-2 text-secondary small">
                            {sectionPercentile.toFixed(2)}%
                          </td>
                        </tr>
                        <tr className="bg-warning bg-opacity-25">
                          <td className="py-2 px-2 fw-medium text-secondary small">
                            Total
                          </td>
                          <td className="py-2 px-2 fw-medium text-secondary small">
                            {sectionScore}
                          </td>
                          <td className="py-2 px-2 fw-medium text-secondary small">
                            {sectionTime}
                          </td>
                          <td className="py-2 px-2 fw-medium text-secondary small">
                            -
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "sectional" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("sectional")}
                  >
                    Sectional Comparison
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "toppers" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("toppers")}
                  >
                    Topper's Comparison
                  </button>
                </li>
              </ul>

              {/* Tab content */}
              <div className="tab-content mt-3">
                {/* Sectional Comparison Tab */}
                {activeTab === "sectional" && (
                  <div className="bg-light px-4 rounded">
                    <div className="row">
                      <div className="col-12">
                        <SectionalComparisonChart
                          // User data from attemptDetails
                          userScore={score}
                          userTime={totalTimeSpent}
                          userCorrect={correctAnswers}
                          userIncorrect={incorrectAnswers}
                          userUnattempted={unattempted}
                          // Matched user data from topPerformers
                          matchedTopperScore={userRank.userScore}
                          matchedTopperTime={userRank.userTime}
                          matchedTopperCorrect={userRank.correctAnswers}
                          matchedTopperIncorrect={userRank.incorrectAnswers}
                          matchedTopperUnattempted={
                            userRank.unattemptedQuestions
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Toppers Comparison Tab */}
                {activeTab === "toppers" && (
                  <div className="bg-light p-4 rounded">
                    <h5 className="small fw-medium mb-4 text-center">
                      Top Performers
                    </h5>
                    <ToppersComparisonComponent
                      attemptDetails={attemptDetails}
                      ranking={ranking}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockReport;
